import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { User } from "./usermodel";
// import { UsuarioService } from './usuario/usuario.service';

@Injectable({
  providedIn: "root",
})
export class AutenticacaoService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(
    private httpClient: HttpClient // private usuarioService:UsuarioService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.getValue();
  }

  autenticar(usuario: string, senha: string) // : Observable<HttpResponse<any>>
  {
    // return this.httpClient.post('http://44.212.0.76:8081/find-records/account/auth',{
    return this.httpClient
      .post(
        "https://44.212.0.76/find-records/account/auth",
        {
          user: usuario,
          password: senha,
        },
        { observe: "response" }
      )
      .pipe(
        // tap((res) => {
        //   // const authToken = res.headers.get("X-Access-Token") ?? "";
        //   //  this.usuarioService.salvaToken(authToken);
        // })
        map((user : any) => {
          localStorage.setItem("currentUser", JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem("username");
    this.currentUserSubject.next(null);
    
  }

  //set name user new in storage
  setUserName(username: string) {
    localStorage.setItem("username", JSON.stringify(username));
  }

  getUserName() {
    return JSON.parse(localStorage.getItem("username"));
  }
}
