import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 7,
        isLayout: true
    },
    /* {
      id: 8,
      label: 'MENUITEMS.FORMS.TEXT',
      icon: 'bxs-eraser',
      badge: {
          variant: 'danger',
          text: 'MENUITEMS.FORMS.BADGE',
      },
      subItems: [
          {
              id: 9,
              label: 'MENUITEMS.FORMS.LIST.CADASTRO',
              link: '/usuario/cadastro',
              parentId: 8
          },
          {
            id: 10,
            label: 'MENUITEMS.FORMS.LIST.LISTA',
            link: '/usuario/lista',
            parentId: 8
        },

      ]
  }, */
  {
    id: 11,
    label: 'MENUITEMS.RECORDS.TEXT',
    icon: 'bxl-audible',
    badge: {
        variant: 'danger',
        text: 'MENUITEMS.RECORDS.BADGE',
    },
    subItems: [
        {
            id: 12,
            label: 'MENUITEMS.RECORDS.LIST.LISTA',
            link: '/gravacoes/lista',
            parentId: 11
        },
        {
          id: 13,
          label: 'MENUITEMS.RECORDS.LIST.DOWNLOAD',
          link: '/gravacoes/download',
          parentId:11
        }
    ]
}


];

