<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
              <p>© {{year}} Power by <a href="https://royalesolutions.com.br/">Royale</a></p>
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                </div>
            </div>
        </div>
    </div>
</footer>
